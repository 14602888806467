import { DeviceDetails, SeamProvider } from '@seamapi/react'
import { ILayout } from '../../components/ILayout'
import { navTo } from '../../browserHistory'
import { useParams } from 'react-router'
import { Button } from 'konsta/react'
import { BsLightningCharge } from 'react-icons/bs'
import React from 'react'
import { appShareStore } from '../../store/appShareStore'
import { useGetSeamClientSession } from '../../hooks/useGetSeamClientSession'
import { Manufacturer } from '../../constants/property'
import { paths } from '../../routes/path'

export const SmartLockDetail = () => {
  const { setAppShareConfig } = appShareStore()
  const { data } = useGetSeamClientSession();
  const params = useParams<{ device_id: string }>()
  const deviceId = params.device_id

  const handleActivate = () => {
    setAppShareConfig({ smartLockSerialNumber: deviceId })
    navTo(paths.selectCommunity(Manufacturer.SMART_LOCK))
  }

  return (
    <ILayout onBack={() => navTo('/smart-locks')} isHome title="WiFi Smart Lock">
      <div className="mb-14-safe">
        {data?.token && (
          <SeamProvider clientSessionToken={data?.token}>
            <DeviceDetails deviceId={deviceId} />
          </SeamProvider>
        )}
      </div>
      <div className="fixed md:absolute bottom-0 w-full">
        <Button onClick={() => handleActivate()} large className="rounded-none">
          Activate property <BsLightningCharge/>
        </Button>
      </div>
    </ILayout>
  )
}
