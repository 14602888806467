import { useEffect } from 'react'

export const CheckRedirect = ({ children }: any) => {
  // Fix the old version
  useEffect(() => {
    if (window.location.host === 'nternow-z-wave.netlify.app') {
      window.open('https://install.nternow.com', '_self');
    }
  }, [])

  return (
    <>
      {children}
    </>
  )
};