import { useMutation } from '@tanstack/react-query'
import { mobileService } from '../services/mobile.service'
import { useAppConfig } from '../store/appConfig'
import { Manufacturer, ManufacturerValue } from '../constants/property'

export const useAttachHub = () => {
  const config = useAppConfig();
  return useMutation({
    mutationFn: ({ lockSerialNumber, id, lockManufacturer = Manufacturer.Ezlo }: {
      lockSerialNumber: string,
      id: number,
      lockManufacturer?: string,
    }) => mobileService.patch(`/properties/${id}/lock`, {
      lockSerialNumber,
      lockManufacturer: ManufacturerValue[lockManufacturer],
    }, {
      headers: {
        'x-auth-token': config.token,
      }
    }),
  })
}

export const useLockInstallPayload = () => {
  const config = useAppConfig();
  return useMutation({
    mutationFn: ({ propertyId, payload }: {
      propertyId: number,
      payload: string,
    }) => mobileService.patch(`/properties/${propertyId}/install`, {
      payload,
    }, {
      headers: {
        'x-auth-token': config.token,
      }
    }),
  })
}