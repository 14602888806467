import React, { useMemo, useState } from 'react'
import { Block, Link, List, Navbar, Page, Searchbar } from 'konsta/react'
import { PropertyItem } from './components/PropertyItem'
import { useGetListProperties } from '../../../hooks/useGetListProperties'
import { PropertyItemGeo } from '../../../types/Property'
import { PopupCustom } from '../../../components/PopupCustom'
import { Manufacturer } from '../../../constants/property'

interface Props {
  propertyIds: number[];
  popupOpened: boolean;
  setPopupOpened: (value: boolean) => void;
  manufacturer?: Manufacturer;
}

export const SelectProperty = ({ propertyIds, popupOpened, setPopupOpened, manufacturer }: Props) => {
  const [search, setSearch] = useState<string>('');
  const { data: items, isLoading } = useGetListProperties(propertyIds);

  const properties = useMemo(() => {
    let list = items as PropertyItemGeo[];
    if (!search) {
      return list;
    }
    return list.filter((item) =>
      item.address?.toLowerCase().includes(search.toLowerCase()) ||
      item.property_id.toString().includes(search.toLowerCase()) ||
      item.lotNumber.toString().includes(search.toLowerCase())
    );
  }, [items, location, search])

  return (
    <PopupCustom className='w-full' opened={popupOpened} onBackdropClick={() => setPopupOpened(false)}>
      <Page>
        <Navbar
          title='Select property'
          right={
            <Link className='text-sm' navbar onClick={() => setPopupOpened(false)}>
              Close
            </Link>
          }
        />
        <Block className="mb-safe">
          <div className="text-center mb-4">
            <h1 className='text-lg'>Please find better reception.</h1>
          </div>
          <Searchbar placeholder='Enter search text' onInput={(e) => {
            setSearch(e.target.value);
          }} />
          <List>
            {properties?.map((property) => (
              <PropertyItem manufacturer={manufacturer} key={property.property_id} property={property} />
            ))}
          </List>
        </Block>
      </Page>
    </PopupCustom>
  )
}
