import { SeamProvider, DeviceTable } from "@seamapi/react"
import { navTo } from '../../browserHistory'
import { ILayout } from '../../components/ILayout'
import { useGetSeamClientSession } from '../../hooks/useGetSeamClientSession'
import { paths } from '../../routes/path'
import { Fab, Preloader } from 'konsta/react'
import React from 'react'
import { queryClient } from '../../utils/reactQuery'
import { MdRefresh } from 'react-icons/md'

export const SmartLockList = () => {
  const { data, isFetching } = useGetSeamClientSession();

  const refetch = () => {
    queryClient.invalidateQueries({
      queryKey: ['devices'],
    })
  }

  return (
    <ILayout title="WiFi Smart Lock">
      {isFetching && <div className="flex items-center justify-center h-screen">
        <Preloader />
      </div>}
      {data?.token &&
        <SeamProvider queryClient={queryClient} clientSessionToken={data.token}>
          <DeviceTable onDeviceClick={(device) => navTo(paths.smartLockDetail(device))}/>
        </SeamProvider>
      }
      <Fab
        onClick={() => refetch()}
        className='absolute bg-green-700 hover:bg-green-600 right-4-safe bottom-20-safe z-40'
        icon={<MdRefresh />}
      />
    </ILayout>
  )
}
