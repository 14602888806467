import { LockDispositionStatus } from '../types/Property'

export const DISPOSITION_LABELS = {
  [LockDispositionStatus.PROPERTY_AVAILABLE]: 'Available',
  [LockDispositionStatus.PROPERTY_STAFF_ACCESS]: 'Staff Access',
  [LockDispositionStatus.PROPERTY_UNDER_MAINTENANCE]: 'Under Maintenance',
}

export enum Manufacturer {
  SMART_LOCK = 'smart-locks',
  Ezlo = 'ezlo',
  IGLOO_IOT = 'igloo-iot',
}

export const ManufacturerValue: {[key: string]: string} = {
  [Manufacturer.SMART_LOCK]: 'Smart Lock',
  [Manufacturer.Ezlo]: 'Ezlo',
  [Manufacturer.IGLOO_IOT]: 'Igloo IoT',
}