import React, { useState } from 'react';
import { MdLock, MdScanner } from 'react-icons/md';
import { Block, Button, List, ListInput } from 'konsta/react';
import IglooHomeImage from '../../../assets/Igloohome.png';
import { Controller, useForm } from 'react-hook-form';
import { useAttachHub, useLockInstallPayload } from '../../../hooks/useAttachHub';
import { GlobalLoading } from '../../../components/GlobalLoading';
import { useErrorDialog } from '../../../components/IErrorDialog';
import { useMutationSaveCoordinates } from '../../../hooks/useMutationSaveCoordinates';
import { useGeoLocation } from '../../../hooks/useGeoLocation';
import { BsLightningCharge } from 'react-icons/bs';
import { appShareStore } from '../../../store/appShareStore';
import { Scanner } from '@yudiel/react-qr-scanner';
import { Manufacturer } from '../../../constants/property'

interface Props {
  nextStep: () => void;
  propertyId: number;
}

export const SubmitIglooIOTSerialNumber = ({ propertyId, nextStep }: Props) => {
  const { deviceId, resetAppShareConfig } = appShareStore();
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      serial_number: deviceId
    }
  });
  const { mutateAsync, isPending } = useAttachHub();
  const { location } = useGeoLocation(true);
  const { mutateAsync: saveCoordinate, isPending: isPendingCoordinate } = useMutationSaveCoordinates();
  const { mutateAsync: saveLock, isPending: isPendingSaveLock } = useLockInstallPayload();
  const { showErrorDialog } = useErrorDialog();

  // State to toggle between manual entry and QR scanner
  const [isScanning, setIsScanning] = useState(false);

  const handleAttach = async (data: any) => {
    resetAppShareConfig();
    try {
      const res = await mutateAsync({
        id: propertyId,
        lockSerialNumber: data.serial_number,
        lockManufacturer: Manufacturer.IGLOO_IOT
      });
      if (res.data.error) {
        showErrorDialog(res.data.message);
      } else {
        if (!location) {
          showErrorDialog('Location not turned on');
          return;
        }
        await Promise.all([
          saveLock({
            propertyId: propertyId, payload: 'Igloo IoT' }
          ),
          saveCoordinate({
            id: propertyId, latitude: location?.coords.latitude, longitude: location?.coords.longitude
          })
        ]);
        nextStep();
      }
    } catch (e: any) {
      showErrorDialog(e.message);
    }
  };

  const handleScan = (url: string) => {
    const exactValue = url.split('/').pop();
    setValue('serial_number', exactValue);
    setIsScanning(false); // Close scanner after successful scan
  };

  return (
    <>
      <GlobalLoading loading={isPending || isPendingCoordinate || isPendingSaveLock} text={(isPendingCoordinate || isPendingSaveLock) ? 'Saving lock configuration' : isPending ? 'Activating ...' : ''}/>
      <Block className="mb-safe">
        <div className="container">
          {!isScanning && <>
            <img src={IglooHomeImage} className="m-auto h-[250px] p-3 mb-5" alt="Submit lock" />
            <h1 className="text-lg m-2 text-center font-bold">Please Enter the lock Serial Number found on the igloo
              system</h1>
          </>}

          {/* Toggle Button to switch between QR scanning and manual entry */}
          <Button large outline onClick={() => setIsScanning(!isScanning)} className="mb-5 mx-auto w-[200px]">
            {isScanning ? "Enter Serial Number Manually" :<><MdScanner /> Scan QR Code</>}
          </Button>

          {isScanning ? (
            // QR Scanner Component
            <div className="qr-scanner">
              <Scanner onScan={(result) => handleScan(result[0].rawValue)} />
            </div>
          ) : (
            // Manual Entry Form
            <form onSubmit={handleSubmit(handleAttach)}>
              <List strongIos insetIos>
                <Controller
                  control={control}
                  name="serial_number"
                  render={({ field }) => (
                    <ListInput
                      floatingLabel={false}
                      media={<MdLock />}
                      type="text"
                      required
                      autoComplete="off"
                      placeholder="Serial number"
                      {...field}
                    />
                  )}
                />
              </List>
              <Button large className="mt-10 flex gap-1">
                Activate <BsLightningCharge />
              </Button>
            </form>
          )}
        </div>
      </Block>
    </>
  );
};
