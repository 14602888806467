export const HELP_CONTACT = 'tel:+1 (844) 531-1283'

export const SMART_LOCKS_BUILDERS = [
  0,
  10,
  162,
  190,
  191,
  192,
  374,
  381,
  382,
  408,
  409,
  466,
  491,
  501,
  502,
  506,
  527,
  547,
  551,
  552,
  542
];