import { useQuery } from '@tanstack/react-query'
import { mobileService } from '../services/mobile.service'
import { SeamClientSession } from '../types/Seam'
import { useAppNNConfig } from '../store/appNNConfig'

export const useGetSeamClientSession = () => {
  const { token } = useAppNNConfig();

  return useQuery<SeamClientSession>(
    {
      queryKey: ['useGetSeamClientSession', token],
      refetchOnWindowFocus: false,
      retry: 0,
      staleTime: 1000 * 60 * 5,
      queryFn: async () => {
        return mobileService.post(`/hub-accounts/generate-seam-client-session`).then(item => item.data.token)
      }
    })
}