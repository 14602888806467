import { Block, List, ListItem, Page, Searchbar } from 'konsta/react'
import React, { useMemo } from 'react'
import { useGetCommunities } from '../../hooks/useGetCommunities'
import { ILayout } from '../../components/ILayout'
import { SelectProperty } from '../Commons/SelectProperty'
import { useParams } from 'react-router'
import { Manufacturer } from '../../constants/property'
import { useCheckSmartLock } from '../../hooks/useCheckSmartLock'

export function SelectCommunity({ activate }: { activate?: 'lock' | 'property' }) {
  const params = useParams<{ manufacturer: Manufacturer }>()
  const manufacturer = params.manufacturer;
  useCheckSmartLock(manufacturer);
  const { data: communities} = useGetCommunities();
  const [search, setSearch] = React.useState<string>('');
  const [popupOpened, setPopupOpened] = React.useState<boolean>(false);
  const [propertyIds, setPropertyIds] = React.useState<number[]>([]);

  const filteredCommunities = useMemo(() => {
    if (!communities) {
      return [];
    }
    if (!search) {
      return communities;
    }
    return communities?.filter((item) => item.name?.toLowerCase().includes(search.toLowerCase()));
  }, [communities, search])

  const handleSelectCommunity = (propertyIds: number[]) => {
    setPropertyIds(propertyIds);
    setPopupOpened(true);
  }

  return (
    <ILayout>
      <Page>
        <Block className='p-4'>
          <h1 className='text-xl text-center'>Select the community you want to activate the lock</h1>
          <div className='mt-4'>
            <Searchbar placeholder='Enter search text' onInput={(e) => setSearch(e.target.value)} />
            <List>
              {filteredCommunities?.map((item) => (
                <ListItem onClick={() => handleSelectCommunity(item.properties)} title={
                  <p className="flex gap-2 my-1 items-center">{item.name}</p>
                } key={item.community_id} link>
                </ListItem>
              ))}
            </List>
          </div>
        </Block>
      </Page>
      <SelectProperty manufacturer={manufacturer} propertyIds={propertyIds} popupOpened={popupOpened} setPopupOpened={setPopupOpened} />
    </ILayout>
  )
}
