import { useEffect } from 'react'
import { Manufacturer } from '../constants/property'
import { navTo } from '../browserHistory'
import { appShareStore } from '../store/appShareStore'

export const useCheckSmartLock = (manufacturer: string) => {
  const { smartLockSerialNumber } = appShareStore();
  useEffect(() => {
    if (manufacturer === Manufacturer.SMART_LOCK && !smartLockSerialNumber) {
      navTo(`/smart-locks`)
    }
  }, [smartLockSerialNumber, manufacturer])
}