import { Icon, Link, List, ListItem, Navbar, Page, Panel, Searchbar } from 'konsta/react'
import {
  MdMenu,
  MdMenuOpen,
  MdRealEstateAgent,
  MdOutlineDoorFront,
  MdOutlineHub,
  MdOutlineLogout,
  MdOutlineLogin,
  MdOutlineHome,
  MdOutlineLockOpen,
  MdSignalWifi3BarLock,
  MdArrowBack
} from 'react-icons/md'
import React, { useId, useState } from 'react'
import { navTo } from '../browserHistory'
import { logOut } from '../services/auth'
import { MenuHelp } from './MenuHelp'
import { useAppNNConfig } from '../store/appNNConfig'
import { useAppConfig } from '../store/appConfig'
import classnames from 'classnames'
import { paths } from '../routes/path'
import { Manufacturer } from '../constants/property'
import { useEnableLock } from '../hooks/useEnableLock'

interface NavProps {
  isHome?: boolean;
  title?: string;
  hasSearch?: boolean;
  onSearch?: (value: string) => void;
  onBack?: () => void;
}

export const NNDrawer = ({ hasSearch, onSearch, title, isHome, onBack }: NavProps) => {
  const { resetAppConfig:resetAppNNConfig, token: tokenNN } = useAppNNConfig()
  const { resetAppConfig, loginType: isEzloLogin } = useAppConfig()
  const searchRef = useId()
  const [leftPanelOpened, setLeftPanelOpened] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const { isShowLock, isSmartLock } = useEnableLock();

  const handleSearch = (e: any) => {
    setSearchQuery(e.target.value)
    onSearch && onSearch(e.target.value)
  }

  const logoutNternow = () => {
    setLeftPanelOpened(false)
    resetAppNNConfig()
    logOut()
    navTo('/login-nn')
  }

  const logoutHub = () => {
    setLeftPanelOpened(false)
    resetAppConfig()
    logOut()
    navTo('/login-hub')
  }

  return (
    <>
      <Navbar
        titleClassName="text-primary"
        title={title ? title : 'NterNow Smart Lock Tool'}
        left={
          onBack ? <Link navbar iconOnly onClick={() => onBack()}>
            <Icon ios={<MdArrowBack className='w-6 h-6' />} material={<MdArrowBack className='w-6 h-6' />} />
          </Link> : <Link navbar iconOnly onClick={() => setLeftPanelOpened(true)}>
            <Icon ios={<MdMenu className='w-6 h-6' />} material={<MdMenu className='w-6 h-6' />} />
          </Link>
        }
        right={!isHome &&
          <Link navbar onClick={() => {
            setLeftPanelOpened(false);
            navTo('/')
          }}>
            <Icon ios={<MdOutlineHome className='w-6 h-6' />} material={<MdOutlineHome className='w-6 h-6' />} />
          </Link>
        }
        subnavbar={
          hasSearch && (
            <Searchbar
              inputMode={'numeric'}
              inputId={searchRef}
              onInput={handleSearch}
              value={searchQuery}
              onClear={() => {
                setSearchQuery('')
                onSearch && onSearch('')
              }}
            />
          )
        }
      />
      <Panel side='left' opened={leftPanelOpened} onBackdropClick={() => setLeftPanelOpened(false)}>
        <Page>
          <Navbar
            title='NterNow'
            right={
              <Link navbar onClick={() => setLeftPanelOpened(false)}>
                <Icon ios={<MdMenuOpen className='w-6 h-6' />} material={<MdMenuOpen className='w-6 h-6' />} />
              </Link>
            }
          />

          {/* Hubs Management Section */}
          <List strongIos outlineIos>
            <ListItem
              onClick={() => {
                setLeftPanelOpened(false)
                navTo('/servers')
              }}
              titleWrapClassName="py-1.5"
              title={
                <p className={classnames('flex items-center text-xs', {
                  'text-gray-300': !isEzloLogin,
                })}>
                  <MdOutlineHub className={classnames('w-4 h-4 mr-1', {
                    'text-gray-300': !isEzloLogin,
                    'text-green-500': !!isEzloLogin,
                  })}></MdOutlineHub>Manage Hub
                </p>
              }
              link
            />
            <ListItem
              titleWrapClassName="py-1.5"
              onClick={() => logoutHub()}
              title={
                <p className='flex items-center text-sm'>
                  {!!isEzloLogin ? <>
                    <MdOutlineLogout className='w-4 h-4 mr-1 text-green-500'></MdOutlineLogout>Logout hub</>
                    : <><MdOutlineLogin className='w-4 h-4 mr-1 text-green-500'></MdOutlineLogin>Login to manage hub</>}
                </p>
              }
              chevronIcon={<></>}
              link
            />
          </List>

          {/* Property Management Section */}
          <List strongIos outlineIos>
            <ListItem
              titleWrapClassName="py-1.5"
              onClick={() => {
                setLeftPanelOpened(false)
                navTo('/property-manage')
              }}
              title={
                <p className={classnames('flex items-center text-xs', {
                  'text-gray-300': !tokenNN,
                })}>
                  <MdRealEstateAgent className={classnames('w-4 h-4 mr-1', {
                    'text-gray-300': !tokenNN,
                    'text-primary': !!tokenNN,
                  })}></MdRealEstateAgent>Mark as Sold & Reset
                </p>
              }
              link
            />
            <ListItem
              titleWrapClassName="py-1.5"
              onClick={() => {
                setLeftPanelOpened(false)
                navTo('/hub-manage')
              }}
              title={
                <p className={classnames('flex items-center text-xs', {
                  'text-gray-300': !tokenNN,
                })}>
                  <MdOutlineDoorFront className={classnames('w-4 h-4 mr-1', {
                    'text-gray-300': !tokenNN,
                    'text-primary': !!tokenNN,
                  })}></MdOutlineDoorFront>Property Access
                </p>
              }
              link
            />
            <ListItem
              titleWrapClassName="py-1.5"
              onClick={() => {
                setLeftPanelOpened(false)
                navTo(paths.selectCommunity(Manufacturer.Ezlo))
              }}
              title={
                <p className={classnames('flex items-center text-xs', {
                  'text-gray-300': !tokenNN,
                })}>
                  <MdOutlineHome className={classnames('w-4 h-4 mr-1', {
                    'text-gray-300': !tokenNN,
                    'text-primary': !!tokenNN,
                  })}></MdOutlineHome>Activate Property
                </p>
              }
              link
            />
            {isSmartLock && <ListItem
              titleWrapClassName="py-1.5"
              onClick={() => {
                setLeftPanelOpened(false)
                navTo(paths.selectCommunity(Manufacturer.SMART_LOCK))
              }}
              title={
                <p className={classnames('flex items-center text-xs', {
                  'text-gray-300': !tokenNN,
                })}>
                  <MdSignalWifi3BarLock className={classnames('w-4 h-4 mr-1', {
                    'text-gray-300': !tokenNN,
                    'text-primary': !!tokenNN,
                  })}></MdSignalWifi3BarLock>Activate WiFi Smart Lock
                </p>
              }
              link
            />}
            {isShowLock && <ListItem
              titleWrapClassName="py-1.5"
              onClick={() => {
                setLeftPanelOpened(false)
                navTo(paths.selectCommunity(Manufacturer.IGLOO_IOT))
              }}
              title={
                <p className={classnames('flex items-center text-xs', {
                  'text-gray-300': !tokenNN,
                })}>
                  <MdOutlineLockOpen className={classnames('w-4 h-4 mr-1', {
                    'text-gray-300': !tokenNN,
                    'text-primary': !!tokenNN,
                  })}></MdOutlineLockOpen>Activate Lock
                </p>
              }
              link
            />}
            <ListItem
              titleWrapClassName="py-1.5"
              onClick={() => logoutNternow()}
              title={
                <p className='flex items-center text-sm text-primary'>
                  {!!tokenNN ? <>
                      <MdOutlineLogout className='w-4 h-4 mr-1'></MdOutlineLogout>Logout Nternow</>
                    : <><MdOutlineLogin className='w-4 h-4 mr-1'></MdOutlineLogin>Login to Nternow</>}
                </p>
              }
              chevronIcon={<></>}
              link
            />
          </List>

          <MenuHelp logout={logoutNternow} />
        </Page>
      </Panel>
    </>
  )
}
