import { useQuery } from '@tanstack/react-query'
import { mobileService } from '../services/mobile.service'
import { useAppNNConfig } from '../store/appNNConfig'

export const useHasSmartLock = () => {
  const { token } = useAppNNConfig();
  return useQuery<{ hasSmartLock: boolean }>(
    {
      queryKey: ['useHasSmartLock', token],
      enabled: !!token,
      retry: 0,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60,
      queryFn: () => mobileService.get(`/smart-lock/check-has-smart-lock`).then(item => item.data)
    })
}