import React from 'react'
import { MdLock } from 'react-icons/md'
import { Block, Button, List, ListInput } from 'konsta/react'
import { Controller, useForm } from 'react-hook-form'
import { useAttachHub } from '../../../hooks/useAttachHub'
import { GlobalLoading } from '../../../components/GlobalLoading'
import { useErrorDialog } from '../../../components/IErrorDialog'
import { useMutationSaveCoordinates } from '../../../hooks/useMutationSaveCoordinates'
import { useGeoLocation } from '../../../hooks/useGeoLocation'
import { BsLightningCharge } from 'react-icons/bs'
import { appShareStore } from '../../../store/appShareStore'
import { Manufacturer } from '../../../constants/property'

// Manufacturer-specific content configuration

interface Props {
  nextStep: () => void;
  propertyId: number;
}

export const SubmitSmartLockSerialNumber = ({ propertyId, nextStep }: Props) => {
  const { smartLockSerialNumber, resetAppShareConfig } = appShareStore();

  // Initialize react-hook-form
  const { control, handleSubmit } = useForm({
    defaultValues: {
      serial_number: smartLockSerialNumber,
    },
  });

  // Hooks
  const { mutateAsync, isPending } = useAttachHub();
  const { location } = useGeoLocation(true);
  const { mutateAsync: saveCoordinate, isPending: isPendingCoordinate } = useMutationSaveCoordinates();
  const { showErrorDialog } = useErrorDialog();

  // Handle Attach
  const handleAttach = async (data: any) => {
    try {
      const res = await mutateAsync({
        id: propertyId,
        lockSerialNumber: data.serial_number,
        lockManufacturer: Manufacturer.SMART_LOCK
      });

      if (res.data.error) {
        showErrorDialog(res.data.message);
        return;
      }

      if (!location) {
        showErrorDialog('Location is not turned on');
        return;
      }

      await saveCoordinate({
        id: propertyId,
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
      });
      nextStep();
    } catch (e: any) {
      showErrorDialog(e.message);
    }
  };

  return (
    <>
      {/* Global Loading Spinner */}
      <GlobalLoading
        loading={isPending || isPendingCoordinate}
        text={
          isPendingCoordinate
            ? 'Saving coordinates...'
            : isPending
              ? 'Activating...'
              : ''
        }
      />

      {/* Main Block */}
      <Block className="mb-safe">
        <div className="container">
          {/* Manufacturer-Specific Content */}
          <h1 className="text-xl font-bold mb-4">Once you click activate, we process these actions</h1>
          <ul className="list-disc ml-5">
            <li>Add automation codes.</li>
            <li>Update geo-coordinates.</li>
            <li>Mark the property as accessible.</li>
          </ul>

          {/* Form */}
          <form onSubmit={handleSubmit(handleAttach)}>
            {/* Serial Number Input */}
            <List className="hidden" strongIos insetIos>
              <Controller
                control={control}
                name="serial_number"
                render={({ field }) => (
                  <ListInput
                    media={<MdLock />}
                    type="text"
                    required
                    autoComplete="off"
                    placeholder="Serial number"
                    {...field}
                  />
                )}
              />
            </List>

            {/* Submit Button */}
            <Button
              large
              className="mt-10 flex items-center justify-center gap-1"
            >
              Activate <BsLightningCharge />
            </Button>
          </form>
        </div>
      </Block>
    </>
  );
};
