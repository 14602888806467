import React from 'react'
import { Block, Button, Link } from 'konsta/react'
import { MdOutlinePhone } from 'react-icons/md'
import { usePropertyAccessCode } from '../../hooks/usePropertyAccessCode'
import { GlobalLoading } from '../GlobalLoading'
import { HELP_CONTACT } from '../../constants/common'
import { navTo } from '../../browserHistory'
import { appShareStore } from '../../store/appShareStore'

interface Props {
  propertyId: number;
}

export const GetAccessCode = ({ propertyId }: Props) => {
  const { data, isFetching, refetch } = usePropertyAccessCode(propertyId);
  const { resetAppShareConfig } = appShareStore();

  const handleDone = () => {
    resetAppShareConfig();
    navTo('/');
  }

  return (
    <>
      <GlobalLoading loading={isFetching} text={isFetching ? 'Generating access code...' : ''}/>
      <Block className="mb-safe">
        <div className="container flex flex-col justify-between h-full">
          <div>
            <h1 className="text-lg m-2 text-center font-bold">Device is activated</h1>
            <p className="text-lg m-2 text-center font-bold text-gray-700">Enter the Digit Code and click unlock</p>
            <p className="text-center text-4xl mt-4 text-primary font-bold">{data?.code || '----'}</p>
          </div>
          {/* move to bottom */}
          <div className="mt-20 items-center flex flex-col">
            <Button large className="mt-10 !bg-black" onClick={() => handleDone()}>
              OK. I'm Done
            </Button>
            <Link className="mt-3">
              <a href={HELP_CONTACT} className="flex gap-1">
                <MdOutlinePhone className="w-4 h-4 mr-1"></MdOutlinePhone>Live Help
              </a>
            </Link>
          </div>
        </div>
      </Block>
    </>
  )
}
