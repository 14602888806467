import { create } from 'zustand'

export type AppShareStoreData = {
  deviceId?: string;
  smartLockSerialNumber?: string;
  manufacturer?: string;
}

type AppConfigMethod = {
  setAppShareConfig: (data: Partial<AppShareStoreData>) => void
  resetAppShareConfig: () => void
}

type AppConfig = AppShareStoreData & AppConfigMethod

export const appShareStore = create<AppConfig>((set) => ({
  setAppShareConfig: (data) => {
    set((state) => ({ ...state, ...data }))
  },
  resetAppShareConfig: () => {
    set((state) => ({
      deviceId: undefined,
      smartLockSerialNumber: undefined,
      manufacturer: undefined,
    }))
  },
}));
