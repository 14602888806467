import React from 'react'

import { useParams } from 'react-router'
import { useGetPropertyDetail } from '../../hooks/useGetPropertyDetail'
import { GlobalLoading } from '../../components/GlobalLoading'
import { SubmitEzloSerialNumber } from './components/SubmitEzloSerialNumber'
import { Link, Navbar } from 'konsta/react'
import { MdKeyboardArrowLeft, MdRefresh } from 'react-icons/md'
import { goBack } from '../../browserHistory'
import ShareLocation from '../../components/ShareLocation/ShareLocation'
import { queryClient } from '../../utils/reactQuery'
import { GetAccessCode } from '../../components/Activation/GetAccessCode'
import { Manufacturer } from '../../constants/property'
import { SubmitIglooIOTSerialNumber } from './components/SubmitIglooIOTSerialNumber'
import { SubmitSmartLockSerialNumber } from './components/SubmitSmartLockSerialNumber'
import { useCheckSmartLock } from '../../hooks/useCheckSmartLock'

enum Steps {
  ShareLocation = 0,
  SubmitSerialNumber = 1,
  GetAccessCode = 2
}

const SubmitSerialNumberMap = {
  [Manufacturer.SMART_LOCK]: SubmitSmartLockSerialNumber,
  [Manufacturer.Ezlo]: SubmitEzloSerialNumber,
  [Manufacturer.IGLOO_IOT]: SubmitIglooIOTSerialNumber,
}

export const PropertyActivate = () => {
  const params = useParams<{ propertyId: string, manufacturer: Manufacturer }>();
  const manufacturer = params.manufacturer;
  useCheckSmartLock(manufacturer);
  const { data: item, isLoading, isFetched } = useGetPropertyDetail(Number(params.propertyId));
  const [step, setStep] = React.useState(Steps.ShareLocation);

  const refreshAccessCode = () => {
    queryClient.invalidateQueries({
      queryKey: ['usePropertyAccessCode'],
    })
  }

  const SubmitSerialNumberComp = SubmitSerialNumberMap[manufacturer];

  return (
    <>
      <GlobalLoading loading={isLoading} />
      <Navbar
        title='Activate Property'
        left={
          <Link className='text-sm' navbar onClick={() => goBack()}>
            <MdKeyboardArrowLeft size='30' />
          </Link>
        }
        right={
          Steps.GetAccessCode === step ? <Link className='text-sm' navbar onClick={() => refreshAccessCode()}>
            <MdRefresh size='30' />
          </Link> : null
        }
      />
      {isFetched && <>
        {step === Steps.ShareLocation && <ShareLocation onShareLocation={() => setStep(Steps.SubmitSerialNumber)}/>}
        {step === Steps.SubmitSerialNumber && <SubmitSerialNumberComp nextStep={() => setStep(Steps.GetAccessCode)} propertyId={Number(params.propertyId)} />}
        {step === Steps.GetAccessCode && <GetAccessCode propertyId={Number(params.propertyId)} />}
      </>}
    </>
  )
}

